import { throwCustomFunction } from '@/commons/ErrorHandle'
import { setStorage } from '@/commons/SessionStorageUtils'
import AxiosAdapter from '@/request/AxiosAdapter'
import { Ref } from 'vue'
import { mobileReg, emailReg } from '../commons/unit'


export interface FormData {
    username: string;
    password: string;
}

export const loginFunc = (formData: FormData, gotoFunc: () => void) => {
    return async () => {
        if (!formData.username) {
          throwCustomFunction('请输入手机号')
        }
        if (!formData.password) {
          throwCustomFunction('请输入验证码')
        }
        if (!(mobileReg.test(formData.username) || emailReg.test(formData.username))) {
          throwCustomFunction('请输入正确的手机号或邮箱')
        } 
        setStorage('Key', formData.username)
        const data = await AxiosAdapter.post<FormData, any>({
          url: '/webElectronic/webLogin',
          data: formData
        })
        setStorage('Token', data.key)
        setStorage('Key', data.phone)
        gotoFunc()
    }
}

export const getCodeFunc = (formData: FormData, show: Ref<boolean>, timerFunc: () => void) => {
    return async () => {
        if (!formData.username) {
          throwCustomFunction('请输入手机号或邮箱')
        }
        if (!(mobileReg.test(formData.username) || emailReg.test(formData.username))) {
          throwCustomFunction('请输入正确的手机号或邮箱')
        } 
        await AxiosAdapter.get({
          url: '/webElectronic/sendAuthCode',
          data: { phone: formData.username }
        })
        show.value = false
        timerFunc()
    }
}