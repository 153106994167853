
import { defineComponent, onUnmounted, reactive, ref } from 'vue'
import { FormData, loginFunc, getCodeFunc } from '../service/Login'
import { useRouter } from "vue-router";

export default defineComponent({
  name: 'Login',
  setup() {
    const show = ref(true)
    const timer = ref(60)

    const formData: FormData = reactive({
      username: '',
      password: ''
    })
    const router = useRouter()

    const login = loginFunc(formData, () => {
      router.push({
        path: '/documents-list',
      })
    })

    // -----------------------------------------------
    let authTimer: NodeJS.Timeout | undefined
    // let authTimer: any
    onUnmounted(() => {
      if (authTimer) clearTimeout(authTimer)
    })
    // 计时器
    const getCode = getCodeFunc(formData, show, () => {
      authTimer = setInterval(() => {
        timer.value--
        if (timer.value <= 0) {
          show.value = true
          authTimer && clearInterval(authTimer)
          timer.value = 0
          authTimer = undefined
        }
      }, 1000)
    })

    // -----------------------------------------------

    return {
      login, formData, getCode, show, timer
    }
  },
})
